<template>
  <div class="main">
    <div class="banner"></div>
    <div class="content">
      <div class="title_b">
        <div><b>产品定位</b></div>
        <div class="btn">全流程一站式活动运营管理平台</div>
        <div class="sub_title">
          支持多类型活动形式及多主题风格的活动开展，提供精细化的运营数据和活动风控管理。
        </div>
      </div>
      <div class="card_3_p">
        <div class="card" v-for="(i, index) in pl" :key="index">
          <img :src="i.img" alt="" />
          <div>{{ i.text }}</div>
          <div class="s">{{ i.s }}</div>
        </div>
      </div>
      <img
        src="../../assets/img/productList/ma/activity_problem_title.png"
        alt=""
      />
      <div class="title_b">
        <b>解决问题</b>
        <div class="sub_title">
          打造快速响应运营需求、前后端分离、活动能力下沉、全局性运营视角的营销活动平台。
        </div>
      </div>
      <div class="sln">
        <div class="card">
          <img
            src="../../assets/img/productList/ma/activity_problem_icon_1.png"
            alt=""
          />
          <div class="b"><b>活动策划配置</b></div>
        </div>
        <div class="img">
          <img
            src="../../assets/img/productList/ma/activity_problem_img.png"
            alt=""
          />
        </div>
        <div class="card">
          <img
            src="../../assets/img/productList/ma/activity_problem_icon_2.png"
            alt=""
          />
          <div class="b"><b>活动发布上线</b></div>
        </div>
      </div>
    </div>
    <div class="pf">
      <div class="t"></div>
      <div class="title_b">
        <b>产品特性</b>
      </div>

      <div class="content">
        <div class="list">
          <div class="title">
            <div class="icon">01</div>
            <img
              src="../../assets/img/productList/ma/activity_characteristic_title_1.png"
              alt=""
            />
          </div>
          <div class="g_list">
            <div>
              <div class="text">活动模板丰富多样</div>
              <div class="text">互动性强，可随时增加</div>
              <div class="text">活动规则及玩法持续优化</div>
            </div>
            <img
              src="../../assets/img/productList/ma/activity_characteristic_1.jpg"
              alt=""
            />
          </div>
        </div>

        <div class="list">
          <div class="title">
            <div class="icon">02</div>
            <img
              src="../../assets/img/productList/ma/activity_characteristic_title_2.png"
              alt=""
            />
          </div>
          <div class="card_3">
            <div class="card" v-for="(i, index) in vopp" :key="index">
              <img :src="i.img" alt="" />
              <div class="b">{{ i.text }}</div>
              <div class="s">{{ i.s }}</div>
            </div>
          </div>
        </div>

        <div class="list">
          <div class="title">
            <div class="icon">03</div>
            <img
              src="../../assets/img/productList/ma/activity_characteristic_title_3.png"
              alt=""
            />
          </div>
          <div class="g_list">
            <div>
              <div class="text">界面配置可视化</div>
              <div class="text">PC端活动配置</div>
              <div class="text">活动信息可视化</div>
            </div>
            <img
              src="../../assets/img/productList/ma/activity_characteristic_3.jpg"
              alt=""
            />
          </div>
        </div>

        <div class="list">
          <div class="title">
            <div class="icon">04</div>
            <img
              src="../../assets/img/productList/ma/activity_characteristic_title_4.png"
              alt=""
            />
          </div>
          <div class="card_3">
            <div class="card" v-for="(i, index) in rtua" :key="index">
              <img :src="i.img" alt="" class="shadow" />
              <div class="b">{{ i.text }}</div>
            </div>
          </div>
        </div>

        <div class="list">
          <div class="title">
            <div class="icon">05</div>
            <img
              src="../../assets/img/productList/ma/activity_characteristic_title_5.png"
              alt=""
            />
          </div>
          <div>
            <img
              src="../../assets/img/productList/ma/activity_characteristic_5.png"
              alt=""
            />
          </div>
        </div>

        <div class="list">
          <div class="title">
            <div class="icon">06</div>
            <img
              src="../../assets/img/productList/ma/activity_characteristic_title_6.png"
              alt=""
            />
          </div>
          <div>
            <img
              src="../../assets/img/productList/ma/activity_characteristic_6.png"
              alt=""
            />
          </div>
        </div>

        <div class="list">
          <div class="title">
            <div class="icon">07</div>
            <img
              src="../../assets/img/productList/ma/activity_characteristic_title_7.png"
              alt=""
            />
          </div>
          <div>
            <img
              src="../../assets/img/productList/ma/activity_characteristic_7.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <div class="ac">
      <div class="title_b">
        <div><b>活动形式</b></div>
        <div class="sub_title">丰富多样活动形式 覆盖更多应用场景</div>
      </div>

      <div class="card_6 content">
        <div class="card" v-for="(i, index) in aclist" :key="index">
          <img :src="i.img" alt="" />
          <div class="text">{{ i.text }}</div>
        </div>
      </div>
    </div>
    <div class="case">
      <div class="title_b">
        <div><b>活动案例</b></div>
        <div class="sub_title">各类应用需求展示</div>
      </div>
      <div class="content card_case">
        <div class="card" v-for="(i, index) in caselist" :key="index">
          <div class="img">
            <img :src="i.img" alt="" />
          </div>
          <div class="title">
            <b>{{ i.title }}</b>
          </div>

          <div class="text_list">
            <div v-for="(item, index) in i.textlist" :key="index">
              <div class="text_title">
                <b>{{ item.title }}</b>
              </div>
              <div v-html="item.text"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pl: [
        {
          img: require("../../assets/img/productList/ma/activity_location_1.png"),
          text: "形式多样  模板丰富",
          s: "Diversity and richness",
        },
        {
          img: require("../../assets/img/productList/ma/activity_location_2.png"),
          text: "可视化配置 快速上线",
          s: "Visual configuration",
        },
        {
          img: require("../../assets/img/productList/ma/activity_location_3.png"),
          text: "数据采集 实时统计",
          s: "Collection and analysis",
        },
      ],
      vopp: [
        {
          img: require("../../assets/img/productList/ma/activity_characteristic_21.png"),
          text: "自建活动",
          s: "活动商品灵活配置",
        },
        {
          img: require("../../assets/img/productList/ma/activity_characteristic_22.png"),
          text: "平台商品库",
          s: "提供商品库管理",
        },
        {
          img: require("../../assets/img/productList/ma/activity_characteristic_23.png"),
          text: "第三方商品库",
          s: "对接第三方商品库",
        },
      ],
      rtua: [
        {
          img: require("../../assets/img/productList/ma/activity_characteristic_41.png"),
          text: "访问控制",
        },
        {
          img: require("../../assets/img/productList/ma/activity_characteristic_42.png"),
          text: "实现精准用户的活动互动",
        },
        {
          img: require("../../assets/img/productList/ma/activity_characteristic_43.png"),
          text: "抽奖控制",
        },
      ],
      aclist: [
        {
          img: require("../../assets/img/productList/ma/activity_form_1.png"),
          text: "九宫格",
        },
        {
          img: require("../../assets/img/productList/ma/activity_form_2.png"),
          text: "大转盘",
        },
        {
          img: require("../../assets/img/productList/ma/activity_form_3.png"),
          text: "摩天轮",
        },
        {
          img: require("../../assets/img/productList/ma/activity_form_4.png"),
          text: "水果机",
        },
        {
          img: require("../../assets/img/productList/ma/activity_form_5.png"),
          text: "刮刮卡",
        },
        {
          img: require("../../assets/img/productList/ma/activity_form_6.png"),
          text: "抽红包",
        },
      ],
      caselist: [
        {
          img: require("../../assets/img/productList/ma/activity_case_1.png"),
          title: "拼团",
          textlist: [
            {
              title: "拼团商品",
              text: "支持单商品/多商品<br/>拼团支持实物、虚拟、业务类商品",
            },
            {
              title: "拼团模式",
              text: "可自定义成团周期<br/>支持凑团、自动成团",
            },
            {
              title: "拼团交易",
              text: "支持成团前支付/成团后支付<br/>可配置原价购买拼团商品<br/>支持拼团价、团长优惠价",
            },
          ],
        },
        {
          img: require("../../assets/img/productList/ma/activity_case_2.png"),
          title: "砍价",
          textlist: [
            {
              title: "砍价商品",
              text: "支持实物、虚拟、业务类商品<br/>可设置用户可发起砍价次数",
            },
            {
              title: "砍价方式",
              text: "可指定每刀最多砍价金额<br/>可指定砍价目标刀数",
            },
            {
              title: "砍价设置",
              text: "可限制参与帮砍用户数<br/>可设置砍价成功支付时效<br/>可自定义设置砍价交互文案",
            },
          ],
        },
        {
          img: require("../../assets/img/productList/ma/activity_case_3.png"),
          title: "助力",
           textlist: [
            {
              title: "助力模式",
              text: "支持简单邀请助力模式<br/>支持集卡游戏助力模式",
            },
            {
              title: "助力设置",
              text: "可限制参与用户助力次数<br/>可设置助力时效<br/>可自定义助力目标/助力成功率",
            },
            {
              title: "抽奖模式",
              text: "支持抽奖类活动抽奖模式<br/>支持抽奖类活动中奖模式",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.banner {
  background-image: url("../../assets/img/productList/ma/banner.jpg");
}
.card_3_p {
  display: flex;
  justify-content: space-between;
  .card {
    width: 280px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #313131;
    padding: 90px 0;
    img {
      margin-bottom: 29px;
    }
    .s {
      font-size: 18px;
      color: #919191;
      margin-top: 10px;
    }
  }
}
.sln {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 80px;
  .img {
    width: 744px;
    height: 337px;
  }
  .card {
    width: 176px;
    text-align: center;
    img {
      width: 60px;
    }
    .b {
      width: 176px;
      height: 34px;
      background-color: #eee6d8;
      border-radius: 17px;
      font-size: 20px;
      line-height: 34px;
      color: #594d38;
      margin-top: 19px;
    }
  }
}
.pf {
  width: 100%;
  background: url("../../assets/img/productList/ma/activity_characteristic_bg.jpg")
    repeat-y;
  background-position: center;
  padding-bottom: 120px;
  .t {
    width: 100%;
    height: 100px;
    background: url("../../assets/img/productList/ma/activity_characteristic_titel.png")
      no-repeat;
    background-position: center top;
  }
  .list {
    width: 1260px;
    background-color: #ffffff;
    box-shadow: 0px 4px 12px 0px rgba(55, 94, 170, 0.06);
    margin-bottom: 40px;
    margin-top: 0;
    &:last-child {
      margin-bottom: 0;
    }
    &:first-child {
      margin-top: 54px;
    }
    .title {
      display: flex;
      height: 74px;
      align-items: center;
      padding: 30px 0 0 30px;

      .icon {
        width: 50px;
        height: 50px;
        background-color: #2896f1;
        box-shadow: 0px 0px 0px 12px #e7edf4;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        line-height: 50px;
        color: #ffffff;
        border-radius: 50%;
        margin-right: 30px;
      }
    }
  }
  .g_list {
    display: flex;
    padding: 40px 69px 22px 49px;
    justify-content: space-between;
    .text {
      font-size: 22px;
      line-height: 60px;
      color: #515151;
      &::before {
        content: "";
        display: inline-block;
        width: 12px;
        height: 12px;
        background-color: #dcc187;
        border-radius: 6px;
        margin: auto;
        margin-right: 56px;
      }
    }
  }
  .card_3 {
    display: flex;
    padding: 67px 15px 46px 15px;
    .card {
      text-align: center;
      flex: 1;
      .img {
        width: 110px;
        height: 110px;
      }
      .shadow {
        width: 110px;
        height: 110px;
        background-color: #ffffff;
        box-shadow: 0px 0px 21px 0px rgba(40, 150, 241, 0.25);
        border: solid 1px #a2d0f7;
        border-radius: 50%;
      }
      .b {
        margin-top: 37px;
        font-size: 22px;
        font-weight: bold;
        color: #000000;
      }
      .s {
        font-size: 18px;
        font-weight: normal;
        color: #818181;
      }
    }
  }
}
.ac {
  width: 100%;
  background: url("../../assets/img/productList/ma/activity_bg.png") no-repeat;
  background-position: center top;
  padding-top: 60px;
  position: relative;
  top: -16px;
  .card_6 {
    display: flex;
    justify-content: space-between;
    margin-top: 77px;
    margin-bottom: 119px;
    .card {
      width: 170px;
      text-align: center;
      font-size: 22px;
      color: #000000;
      .text {
        margin-top: 30px;
      }
    }
  }
}
.case {
  background-color: #f5f5f5;

  padding-bottom: 80px;
  .card_case {
    display: flex;
    justify-content: space-between;
    padding-top: 130px;
    .card {
      width: 380px;
      height: 400px;
      padding-top: 100px;
      position: relative;
      background-color: #ffffff;
      box-shadow: 0px 2px 15px 1px rgba(84, 84, 84, 0.06);
      border-radius: 10px;
      text-align: center;
      .img {
        position: absolute;
        top: -86px;
        left: 50%;
        transform: translateX(-50%);
      }
      .title {
        position: relative;
        font-size: 28px;
        color: #000000;
        z-index: 1;
        &::after {
          position: relative;
          z-index: -1;
          display: block;
          content: "";
          width: 80px;
          height: 10px;
          background-color: #2196f3;
          border-radius: 5px;
          top: -8px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .text_list {
        display: flex;
        flex-direction: column;
        height: 300px;
        justify-content: space-between;
        color: #515151;
        font-size: 16px;
        margin-top: 29px;
        .text_title {
          font-size: 18px;
          line-height: 30px;
          color: #2196f3;
          margin-bottom: 12px;
        }
      }
    }
  }
}
</style>